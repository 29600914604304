// Helpful site for previewing theme changes:
// https://pikock.github.io/bootstrap-magic/app/index.html#!/editor

// colors copied from the doc site's siteConfig.js
$purple: #47325f;
$indigo: #b2a0bb;

$theme-colors: (
  primary: $purple,
  secondary: $indigo,
);

@import "./node_modules/bootstrap/scss/bootstrap.scss";

html,
body,
#root {
  height: 100%;
}

a {
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}

.gh-install-btn {
  background-image: linear-gradient(-180deg, #34d058, #28a745 90%);
  background-color: #28a745;
  background-position: -1px -1px;
  background-repeat: repeat-x;
  background-size: 110% 110%;
  color: #fff;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 14px;
  padding-top: 14px;
  cursor: pointer;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}
.gh-install-btn:hover {
  color: #fff;
  background-color: #269f42;
  background-image: linear-gradient(-180deg, #2fcb53, #269f42 90%);
  background-position: -0.5em;
  border-color: rgba(27, 31, 35, 0.5);
}
.gh-install-btn:active {
  color: #fff;
  background-color: #279f43;
  background-image: none;
  border-color: rgba(27, 31, 35, 0.5);
  box-shadow: inset 0 0.15em 0.3em rgba(27, 31, 35, 0.15);
}

.border-hover:hover {
  @extend .border;
}
.chart-container {
  height: 350px;
}

.sidebar-overflow-ellipsis {
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis;
}

.account-chooser-image:hover {
  border-color: $dark !important;
}
.account-chooser-image {
  border-width: 2px !important;
  border-color: lighten($dark, 25%) !important;
}

.table-sm th {
  border-top: 0;
}

.w-lg-230 {
  width: unset;
}
@include media-breakpoint-up(sm) {
  .w-lg-230 {
    width: 230px;
  }
}

@include media-breakpoint-up(sm) {
  .overflow-sm-auto {
    overflow: auto;
  }
}
